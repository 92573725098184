export const ProgGuides = [
    
    {
        title: "C++ Memory Models",
        link: "Resources/MemoryModels",
    },

    {
        title: "Bootloaders",
        link: "Resources/Bootloader"
    }

]