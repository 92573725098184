
export const otherPage = [
    {
        title: "AWS Summit 2024",
        link: "Resources/AWSSummit2024",
    },
    {
        title: "SidHub Cloud Backend",
        link: "Resources/WebpageCloudArchitecture",
    }
]
