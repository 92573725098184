export const Education = [
    {
        School: "University of Southern California, Viterbi School of Engineering",
        Grad_Date: "May 2025",
        Major: "Bachelors of Science, Computer Science",
        GPA: "3.78"
    },
    {
        School: "University of Southern California, Viterbi School of Engineering",
        Grad_Date: "December 2025",
        Major: "Masters of Science, Computer Science",
        GPA: ""
    },
];
