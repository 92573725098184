export const menuItems = [
    // {
    //    title: "Home",
    //    url: "/Home",
    // },

    {
        title: "Resume",
        url: "/Resume",
    },

    {
        title: "About",
        url: "/Me"
    },

    {
        title: "My Resources and Projects",
        url: "/Resources",
        // submenu: 
        //     [
        //         {
        //             title: "360 Grade Calc",
        //             url: "/Resources/360Grade"
        //         },
        //         // {
        //         //     title: "170 Grade Calc",
        //         //     url: "/Resources/170Grade"
        //         // },
        //         // {
        //         //     title: "104 Grade Calc",
        //         //     url: "/Resources/104Grade"
        //         // },
        //     ]
    },

    {
        title: "Contact Me",
        url: "/Contact",
    }
];
