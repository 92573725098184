export const ResumeHeader = {
    name: "Siddharth Bansal",
    data: [
        'Los Angeles, CA',
        '(323) 391-8668',
        'bansalsi@usc.edu'
    ],
    Links: [

        {
            name: "LinkedIn",
            link: "https://www.linkedin.com/in/siddharth-bansal-20/"
        },
        
        {
            name: "GitHub",
            link: "https://github.com/KANNAHWORLD"
        }
    ]
}