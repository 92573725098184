export const Wikiedits = [
    
    {
        title: "SSE2",
        link: "https://en.wikipedia.org/wiki/SSE2",
    },
    
    {
        title: "SSE4",
        link: "https://en.wikipedia.org/wiki/SSE4",
    }

]